import React from "react";
import responsive from '../assets/responsive.png';
import desarrollo from '../assets/desarrollo.png';
import actualizacion from '../assets/actualizacion.png';
const Services = () => {
    return (
        <div className="container pt-5">
            <p className="text-center fs-1 font-anton" style={{userSelect: 'none'}}>Nuestros Servicios</p>
            
            <div className="row my-5">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-4 mt-1">
                            <div className="card">
                                <div className="card-body">
                                    <img src={responsive} className="img-fluid" style={{width: '250px'}}  alt="prueba de imagen " />
                                    <h5 className="card-title font-roboto">Desarrollo de Páginas Web Altamente Responsivas</h5>
                                    <p className="card-text mt-1 font-quicksand">
                                        Nos aseguramos de que cada sitio web que creamos sea completamente responsive, 
                                        lo que significa que se verá y funcionará perfectamente en computadoras de escritorio, tablets y smartphones,
                                        brindando a tus usuarios una experiencia uniforme sin importar cómo accedan.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mt-1">
                            <div className="card">
                                <div className="card-body">
                                    <img src={desarrollo} className="img-fluid" style={{width: '250px'}}  alt="prueba de imagen " />
                                    <h5 className="card-title font-roboto">Funcionalidades Personalizadas y Desarrollo Avanzado </h5>
                                    <p className="card-text font-quicksand ">
                                    Estamos preparados para convertir tus desafíos en oportunidades y crear soluciones a medida que reflejen la excelencia, la adaptabilidad y la calidad que nos distinguen. Tu éxito es nuestra principal motivación y estamos emocionados de embarcarnos en este viaje contigo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mt-1">
                            <div className="card">
                                <div className="card-body">
                                    <img src={actualizacion} className="img-fluid" style={{width: '250px'}}  alt="prueba de imagen " />
                                    <h5 className="card-title font-roboto">Mantenimiento y Actualizaciones Continuas</h5>
                                    <p className="card-text font-quicksand">
                                    Ofrecemos servicios de mantenimiento continuo para garantizar que tu sitio funcione sin problemas y esté seguro en todo momento. 
                                    Además,estamos aquí para implementar actualizaciones y nuevas características según sea necesario para mantener tu sitio fresco y relevante.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
