import React from 'react';

import './App.css';
import Navbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import Services from './components/Services';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';


export default function App() {

  const navigation =(text)=>{
      const sectionRef = document.getElementById(text);
      if (sectionRef) {
        sectionRef.scrollIntoView({
          behavior: "smooth",
        });
      }
 }

  return (
    <div className="App" style={{backgroundColor:'#fff'}} >

      <Navbar eventHandler={navigation} />
      <div id='home'>
        <Home />
      </div>
      <div id='about' >
        <AboutUs />
      </div>
      <div id='service' >
        <Services />
      </div>
      <div id='contact'>
      <Contact/>
      </div>
      <Footer />

    </div>
  );
}
