import { Button } from "@mui/material";
import React from "react";

const Navbar = ({eventHandler}) => {

  const navigation =(text)=>{
    eventHandler(text);
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler && navbarToggler.getAttribute("aria-expanded") === "true") {
      navbarToggler.click();
    }

    let nav = document.querySelector('.navbar').offsetHeight;
    const componente = document.getElementById(text).offsetTop;

    if(window.innerWidth <= 768) nav = 54

    window.scrollTo({
      top: componente - nav,
      behavior: 'smooth',
    });
    

  }


  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow">
      <div className="container-fluid">
        <a className="navbar-brand font-anton" onClick={()=>navigation("home")}>CODICE INNOVA</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <button className="nav-link font-quicksand" aria-current="page" onClick={()=>navigation("home")}>Inicio</button>
            </li>
            <li className="nav-item">
              <button className="nav-link font-quicksand" aria-current="page" onClick={()=>navigation("about")}>Acerca de</button>
            </li>
            <li className="nav-item">
              <button className="nav-link font-quicksand" aria-current="page" onClick={()=>navigation("service")}>Servicios</button>
            </li>
            <li className="nav-item">
              <button className="nav-link font-quicksand" aria-current="page" onClick={()=>navigation("contact")}>Contacto</button>
            </li>
          </ul>
          <form className="d-flex ms-auto">
            <Button style={{'textTransform' : 'none'}} variant="contained" className="rounded-5 m-2 font-quicksand" onClick={()=>navigation("contact")}>Trabajemos juntos</Button>
            
          </form>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
