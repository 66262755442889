import React from "react";


const Contact = () => {

    return (
        <div className="bg-body-tertiary p-5 d-flex align-items-center">
            
            <div className="container">
                <p className="text-center fs-1 font-anton" style={{ userSelect: 'none' }}>Contacto</p>
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <div className="row ">
                            <div className="col-md-12 mb-2">
                                <i className="fa-brands fa-whatsapp fa-bounce fa-2xl text-success" ></i> 
                                <p className="font-quicksand pt-2 "> +52 686 121 4416 / +52 686 161 2568 </p>    
                            </div>
                            <div className="col-md-12 ">
                                <i class="fa-solid fa-at fa-bounce fa-2xl text-primary"></i>
                                <p className="font-quicksand pt-2 "> contacto@codiceinnova.com </p>
                            </div>
                            <div className="col-md-12">
                                <p className="fw-bold lead font-quicksand">Si desea conocer más acerca de nuestras capacidades y cómo podemos colaborar en el éxito de su proyecto, no dude en ponerse en contacto con nosotros. Estamos a su disposición para ayudarle a hacer realidad su visión.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 order-md-2">
                        {/* Map iframe */}
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53771.45561484315!2d-115.49149041995062!3d32.613751825262874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d7700ca877ddd3%3A0xd40033a0e5cdf59a!2sMexicali%2C%20B.C.!5e0!3m2!1ses-419!2smx!4v1693179828072!5m2!1ses-419!2smx" width="100%" height="350" style={{ border: 0 }} loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
