import React from "react";
import img from '../assets/img1.jpg';
const Home = () => {
    return (
        <div className="bg-primary" style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', minHeight: '80vh', display: 'flex', alignItems: 'center' }}>
            <p className="text-white font-anton-s" style={{ fontSize: '5vw', margin: '20px', marginLeft: '40px', maxWidth: '50%', userSelect: 'none' }}>
                Creando Experiencias que Cuentan
            </p>
        </div>
    );
}

export default Home;
