import React from "react";
import About from '../assets/about.png';

const AboutUs = () => {
    return (
        <div className="p-5 bg-body-tertiary" >
            <div className="container">
                        <div className="container ">
                            <p className="text-center fs-1 font-anton" style={{ userSelect: 'none' }}>Acerca de</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={About} alt="imb about us" className="img-fluid" />
                                </div>
                                <div className="col-md-6 d-flex align-items-center justify-content-center">
                                    <p className="fs-3 font-poiret " style={{ fontSize: '5vw', textAlign: 'center' }}>
                                        En <span className="fw-bold">CODICE INNOVA</span>, nos especializamos en la creación de páginas
                                        web impactantes y funcionales que impulsan la presencia en línea de tu negocio al siguiente nivel.
                                        Con un enfoque en la innovación, el diseño moderno y la usabilidad excepcional, ofrecemos una
                                        gama completa de servicios de desarrollo web que se adaptan a tus necesidades únicas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
        
    );
};

export default AboutUs;
