import React from 'react';
//import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {

  return (
    <footer className="footer p-5">
      <span className="company-name">Codice Innova</span>
      <div className="social-icons">
        <a href="https://www.facebook.com/profile.php?id=100095236249354&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
          <FacebookOutlinedIcon />
        </a>
        <a href="mailto:contacto@codiceinnova.com?subject=Solicitud de Informació&body=Escribe tu petición o en lo que te podemos ayudar" target="_blank" rel="noopener noreferrer">
          <AlternateEmailOutlinedIcon />
        </a>
        <a href="https://www.instagram.com/codiceinnova" target="_blank" rel="noopener noreferrer">
          <InstagramIcon />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
